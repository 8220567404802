<template>
  <div class="flexcolumnstart">
    <div class="personalReport flexcolumnstart" v-if="token || iuid">
      <div class="topContent flexcolumnstart">
        <div class="title marginTop141 newsize">作为树兰医生</div>
        <div class="title newsize">我的2022年</div>
        <div class="doctorInformation flexcolumnstart">
          <div class="flexrowstart aligbaseline">
            <div class="doctorName">{{ detail.name }}</div>
            <div class="post">{{ detail.title }}</div>
          </div>
          <div class="hospital">
            {{ detail.hospital
            }}<span v-if="detail.hospital && detail.department">｜</span
            >{{ detail.department }}
          </div>
        </div>
      </div>
      <div class="content flexcolumnstart">
        <div class="registerTime">「注册时间」</div>
        <div class="registerDate mgbtm16">
          {{ changeData(detail.registrationDay) }}
        </div>
        <div class="commonContent mgbtm16">你第一次使用树兰医生</div>
        <div class="commonContent ">门诊诊间、查房路上、值班深夜</div>
        <div class="commonContent flexrowstart aligbaseline">
          树兰医生伴你度过了<span class="number">{{
            detail.registrationDayCount
          }}</span
          >个日夜
        </div>
        <!-- 线上咨询 -->
        <div class="flexrowbetween alignend mgtop10 mgbtm44">
          <div class="consult">「线上咨询」</div>
          <img src="../../images/personalReport/doctor.png" class="doctorImg" />
        </div>
        <div
          class="flexcolumnstart"
          v-if="
            detail.serviceOrderCount ||
              detail.imMessageCount ||
              detail.imMessageLastTime
          "
        >
          <div class="">2022年，</div>
          <div
            class="commonContent flexrowstart aligbaseline "
            v-if="detail.serviceOrderCount"
          >
            你一共完成了<span class="number">{{
              detail.serviceOrderCount
            }}</span
            >人次的线上咨询
          </div>
          <div
            class="commonContent flexrowstart aligbaseline "
            v-if="detail.imMessageCount"
          >
            用手机逐字敲下<span class="number">{{ detail.imMessageCount }}</span
            >条对患者的回复
          </div>
          <div class="registerDate mgbtm16" v-if="detail.imMessageLastTime">
            {{ changeData(detail.imMessageLastTime) }}
          </div>
          <div>你还在给患者答疑解惑</div>
        </div>
        <div class="flexcolumnstart" v-else>
          <div class="commonContent  specialTitle">
            2022年，近<span class="number">400</span>位医生利用宝贵的休息时间
          </div>
          <div class="commonContent specialTitle">
            为<span class="number">10000</span>余位患者提供了线上咨询服务
          </div>
          <div>让患者随时随地得到专业解答</div>
        </div>
        <!-- 义诊活动 -->
        <div class="yizhen">「义诊活动」</div>
        <div
          class="flexcolumnstart"
          v-if="detail.voluntProjectCount || detail.voluntOrderCount"
        >
          <div
            class="commonContent flexrowstart aligbaseline "
            v-if="detail.voluntProjectCount"
          >
            同时，你还参与了<span class="number">{{
              detail.voluntProjectCount
            }}</span
            >场线上义诊活动
          </div>
          <div
            class="commonContent flexrowstart aligbaseline mgbtm16"
            v-if="detail.voluntOrderCount"
          >
            接诊了<span class="number">{{ detail.voluntOrderCount }}</span
            >个咨询订单，
          </div>
          <div class="commonContent flexrowstart aligbaseline">
            让患者切实感受到树兰的温度
          </div>
        </div>
        <div class="flexcolumnstart" v-else>
          <div class="commonContent flexrowstart aligbaseline mgbtm16">
            为提升公众健康意识，惠及更多患者
          </div>
          <div class="commonContent flexrowstart aligbaseline">
            2022年，结合每月健康节日
          </div>
          <div class="commonContent">
            树兰医院组织开展了近<span class="number">50</span>次线上义诊
          </div>
          <div class="commonContent">
            <span class="number">150</span>余位医生积极参与
          </div>
          <div class="commonContent mgbtm16">
            高质量完成了近<span class="number">2000</span>单义诊咨询
          </div>
        </div>
        <!-- 复诊开药 -->
        <div class="fuzhen">「复诊开药」</div>
        <div class="flexcolumnstart" v-if="detail.consultRecordCount">
          <div class="commonContent flexrowstart aligbaseline ">
            金秋九月，在线复诊开药推出
          </div>
          <div class="commonContent flexrowstart aligbaseline mgbtm16">
            你积极参与，共开出了<span class="number">{{
              detail.consultRecordCount
            }}</span
            >张处方
          </div>
          <div class="commonContent flexrowstart aligbaseline">
            免去复诊患者往返奔波，在家就能续方开药
          </div>
        </div>
        <div class="flexcolumnstart" v-else>
          <div class="commonContent flexrowstart aligbaseline ">
            金秋九月，在线复诊开药推出
          </div>
          <div class="commonContent mgbtm16">
            <span class="number">300</span
            >余位患者实现线上续方，体验足不出户、送药到家的便捷
          </div>
          <div class="commonContent flexrowstart aligbaseline mgbtm16">
            尤其疫情高峰期，复诊开药
          </div>
          <div class="commonContent flexrowstart aligbaseline mgbtm16">
            充分发挥线上优势，减少患者交叉感染风险，
          </div>
          <div class="commonContent flexrowstart aligbaseline">
            缓解到院就医开药压力
          </div>
        </div>
      </div>
      <!-- 患者评价 -->
      <div class="bottomcontent flexcolumnstart" v-if="detail.commentCount">
        <div class="flexrowbetween alignend mgtop10 mgbtm44">
          <div class="patientEvaluate">「患者评价」</div>
          <img src="../../images/personalReport/dianzan.png" class="dianzan" />
        </div>
        <div class="commonContent flexrowstart aligbaseline ">
          这一年
        </div>
        <div class="commonContent flexrowstart aligbaseline mgbtm16">
          你还收到了<span class="number">{{ detail.commentCount }}</span
          >位患者的好评
        </div>
        <div class="commonContent flexrowstart aligbaseline mgbtm16">
          你的专业和真诚，都被患者看在眼里，
        </div>
        <div class="commonContent flexrowstart aligbaseline">
          也记在心中
        </div>
        <div class="btmtitle">感谢你作为树兰医生的积极付出</div>
        <div class="btmsecondTitle mgtop32">期待来年能继续一起</div>
        <div class="btmsecondTitle">为患者提供高质量服务</div>
      </div>
      <div class="bottomcontent flexcolumnstart btmmineheight" v-else>
        <div class="btmtitle">感谢你作为树兰医生的积极付出</div>
        <div class="btmsecondTitle mgtop32">期待来年能继续一起</div>
        <div class="btmsecondTitle">为患者提供高质量服务</div>
      </div>
    </div>
    <div v-else class="logintitle">
      {{ loginTitle }}
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";

export default {
  data() {
    return {
      token: "",
      loginTitle: "",
      iuid: "",
      detail: {}, //年度报告详情
    };
  },

  mounted() {
    this.token = this.$route.query.token;
    this.iuid = this.$route.query.iuid || "";
    console.log(this.token, this.iuid, "token");
    //     没有token或者iuid提示用户升级医生端App
    if (!(this.token || this.iuid)) {
      Dialog.alert({
        confirmButtonColor: "#12BEAE",
        title: "",
        message: "无法查看请升级APP至最新版本",
      }).then(() => {
        this.loginTitle = "无法查看请升级APP至最新版本";
      });
    } else {
      this.getDetail();
      // this._newjssdk();
    }
  },
  methods: {
    //时间转换
    changeData(data) {
      let newData = data ? data.replace(/-/g, ".") : "";
      return newData;
    },
    //获取医生
    getDetail() {
      this.$axios
        .get(`/year/summary/duser/get?iuid=${this.iuid}`, { NewSlToken: true })
        .then((res) => {
          this.detail = res.data || {};
          this._newjssdk();
          let http = "https://" + window.location.host;
          let wxurl = http + "/#/personalReport/index?iuid=" + this.detail.iuid;
          let shareData = {
            title: "我的2022医生年度报告",
            desc: "一起来看看我的树兰医生2022年度报告吧",
            link: wxurl,
            imgUrl: "https://sho-static.shulan.com/minipro/reportLog.png",
          };
          setShareDate.postMessage(JSON.stringify(shareData));
        });
    },
    //微信签名校验
    _jssdk() {
      const _self = this;
      this.$axios
        .post(
          `/wx/config/params?hospitalId=34-6&url=${decodeURIComponent(
            window.location.href
          )}`,
          {},
          { basurlType: "commonurl" }
        )
        .then((res) => {
          console.log(res);
          wx.config({
            debug: res.data.results.debug,
            appId: res.data.results.appid,
            timestamp: res.data.results.timestamp * 1,
            nonceStr: res.data.results.nonceStr,
            signature: res.data.results.signature,
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
          });
          wx.ready(function() {
            let http = window.location.host;
            let wxurl =
              http + "/#/personalReport/index?iuid=" + this.detail.iuid;
            console.log("wx.ready", wxurl);
            //分享链接地址
            wx.onMenuShareAppMessage({
              title: "我的2022医生年度报告",
              desc: "一起来看看我的树兰医生2022年度报告吧",
              link: wxurl,
              imgUrl: "https://sho-static.shulan.com/minipro/reportLog.png",
              success: function() {},
            });
            wx.onMenuShareTimeline({
              title: "我的2022医生年度报告",
              link: wxurl,
              imgUrl: "https://sho-static.shulan.com/minipro/reportLog.png",
              success: function() {},
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    _newjssdk() {
      const _self = this;
      this.$axios
        .post(
          `/wx/config/params?hospitalId=34-6&url=${decodeURIComponent(
            window.location.href
          )}`,
          {},
          { basurlType: "commonurl" }
        )
        .then((res) => {
          console.log(res);
          wx.config({
            debug: res.data.results.debug,
            appId: res.data.results.appid,
            timestamp: res.data.results.timestamp * 1,
            nonceStr: res.data.results.nonceStr,
            signature: res.data.results.signature,
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
          });
          wx.ready(function() {
            let http = "https://" + window.location.host;
            let wxurl =
              http + "/#/personalReport/index?iuid=" + _self.detail.iuid;
            console.log(wxurl, "wxurl");
            wx.onMenuShareAppMessage({
              title: "我的2022医生年度报告",
              desc: "一起来看看我的树兰医生2022年度报告吧",
              link: wxurl,
              imgUrl: "https://sho-static.shulan.com/minipro/reportLog.png",
              success: function() {},
            });
            wx.onMenuShareTimeline({
              title: "我的2022医生年度报告",
              link: wxurl,
              imgUrl: "https://sho-static.shulan.com/minipro/reportLog.png",
              success: function() {},
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.personalReport {
  background: #fffdf1;
  .topContent {
    background: (url("../../images/personalReport/topbg2.png")) no-repeat;
    background-size: 100% 100%;
    .marginTop141 {
      margin-top: 141px;
    }
    .title {
      font-size: 58px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bolder;
      color: #0ea389;
      line-height: 86px;
      margin-left: 55px;
    }
    .doctorInformation {
      margin: 73px 32px 50px 74px;
      font-size: 30px;
      .doctorName {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #615a0f;
        line-height: 67px;
      }
      .post {
        margin-left: 13px;
        font-size: 34px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #615a0f;
        line-height: 48px;
      }
      .hospital {
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;

        font-weight: 400;
        color: #615a0f;
        line-height: 42px;
        margin-top: 25px;
      }
    }
  }
  .content {
    padding: 0 52px 0 75px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;

    font-weight: 400;
    color: #4f5265;
    .registerTime {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: 900;
      color: #94a808;
      line-height: 59px;
      margin: 77px 0 44px;
      margin-left: -18px;
    }
    .registerDate {
      font-size: 50px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: 900;
      color: #04140f;
      line-height: 74px;
    }

    .mgbtm24 {
      margin-bottom: 14px;
    }

    .mgtop10 {
      margin-top: 10px;
    }
    .consult {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: 900;
      color: #5fab16;
      margin-left: -18px;
    }
    .doctorImg {
      width: 276px;
    }
    .mgbtm25 {
      margin-bottom: 25px;
    }
    .mgbtm17 {
      margin-bottom: 17px;
    }
    .mgbtm10 {
      margin-bottom: 10px;
    }
    .yizhen {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: 900;
      color: #26ab16;
      line-height: 59px;
      margin-top: 117px;
      margin-bottom: 29px;
      margin-left: -18px;
    }

    .fuzhen {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: 900;
      color: #0ca593;
      margin: 88px 0 44px;
      margin-left: -18px;
    }
  }
  .commonContent {
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;

    font-weight: 400;
    color: #4f5265;
    line-height: 41px;
  }
  .mgbtm7 {
    margin-bottom: 7px;
  }
  .mgbtm9 {
    margin-bottom: 9px;
  }
  .mgbtm44 {
    margin-bottom: 44px;
  }
  .mgbtm16 {
    margin-bottom: 16px;
  }
  .mgtopbtm {
    margin-top: 7px;
    margin-bottom: 9px;
  }
  .number {
    font-size: 50px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bolder;
    color: #04140f;
    line-height: 74px;
    margin: 0 11px;
  }
  .mgleft16 {
    margin-left: -18px;
  }
  .bottomcontent {
    padding: 0 74px 130px 75px;
    background: #fffdf1 url("../../images/personalReport/bottombg.png")
      no-repeat;
    background-size: 100% 632px;
    background-position: bottom;
    .patientEvaluate {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: bolder;
      color: #e09822;
      line-height: 59px;
      margin-left: -18px;
    }
    .dianzan {
      width: 174px;
    }
    .btmtitle {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: bolder;
      color: #ffffff;
      line-height: 59px;
      margin-bottom: 32px;
      margin-top: 156px;
    }
    .mgtop32 {
      margin-top: 32px;
    }
    .btmsecondTitle {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;

      font-weight: bolder;
      color: #ffffff;
      line-height: 45px;
      text-align: center;
    }
  }
  .btmmineheight {
    padding-top: 100px;
  }
}
.newsize {
  font-family: PingFangSC-Medium, PingFang SC !important;
}
.logintitle {
  margin-top: 20px;
  font-size: 44px;
  font-family: PingFangSC-Medium, PingFang SC !important;
  font-weight: 500;
  color: #040814;
}
</style>
